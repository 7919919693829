import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React, { useState, useEffect, useCallback } from "react"
import { Helmet } from "react-helmet";
import {formatMoney, priceFormat, splitDescription} from "../../utils";
import { getInternetPlanById, getMonthlyAndSingleTimeInternetPlan } from "../../customfunction";
import LoaderComponent from "./LoaderComponent";
import MenuComponent from "./MenuComponent";
const InternetPlansComponent = ({ formData, setformData, setPage, plans, addons, page }: any) => {
    if (!plans || !addons || !formData) {
        return <LoaderComponent values="Loading....."/>;
    }
    const InternetPlansList = plans.internet //getMonthlyAndSingleTimeInternetPlan(plans,formData);
    let seletedPlan = (formData?.plan_id != null) ? InternetPlansList.filter(obj => obj.id === formData?.plan_id)[0] : null;// getInternetPlanById(plans, formData?.plan_id) : null;
    if (InternetPlansList.length!=0) {
        if (formData?.plan_id == null) {
            setformData({ ...formData, plan_id: InternetPlansList[0].id });
        }
    }
    const PlanChangeRadio = async (e: any) => {
        setformData({ ...formData, plan_id: Number(e.target.value) });
    }

    const nextStep = async (e: any) => {
        if (formData?.plan_id != null) {
            localStorage.setItem('pageChange','tvpage');
            setformData({ ...formData, internet_tv_total: InternetPlansList.filter(obj => obj.id === formData?.plan_id)[0].billing[0].monthly.price });
            setPage(formData?.tv_plan==null?5:5);
        }
    }
    const prevStep = async (e: any) => {
        setPage(1);
    }

    useEffect(()=>{
        if(InternetPlansList.length==0){
            setPage(3)
        }
    },[])

    if (InternetPlansList.length!=0 && formData?.plan_id!=null) {
        return (
          <>
              <Helmet>
                  {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
                  <title>Plan – Purple Cow Internet 💜🐄</title>
              </Helmet>
              <main className="max-w-3xl mx-auto">
                  <div className="body--lato height-100-btn-bottom">
                      <div>
                          <div className="success-alert bg-primary-700 text-white">
                              Success. You're in our service area!
                          </div>
                          <div className="text-center logo-center">
                              <Link to="/">
                                  <StaticImage
                                    className="w-24"
                                    layout="constrained"
                                    src="../content/cow.png"
                                    alt="Purple Cow Internet 💜🐄"
                                  />
                              </Link>
                          </div>
                          <MenuComponent page={page} setPage={setPage}></MenuComponent>
                          <h3 className="mt-12 heading-h2">Pick your internet plan.</h3>
                          <div className="selectPlan addon-plan">
                              {InternetPlansList.map((item: any, _index: any) => {
                                  let descriptions=item?.description;
                                  descriptions=typeof descriptions=="undefined"?'':descriptions;
                                  return (<label className="selectPlan__box" key={_index}>
                                      <input
                                        type="radio"
                                        name="selectPlan__"
                                        value={item?.id}
                                        checked={formData?.plan_id == item.id}
                                        onChange={PlanChangeRadio}
                                      />
                                      <div className="selectPlan__box-inner">
                                          <h3 className="h3">{item.billing[0].monthly.invoice_name}</h3>
                                          <h2 className="h2">
                                              ${priceFormat(item?.billing[0].monthly.price)}
                                          </h2>
                                          <span className="plan-type">
                                                / month
                                            </span>
                                          <p className="p4">
                                              <ul>
                                                  {splitDescription(descriptions).map((line, index) => (
                                                    <li key={index}>{line}</li>
                                                  ))}
                                              </ul>
                                          </p>
                                      </div>
                                  </label>)
                              })}
                          </div>
                      </div>
                      <div className="steps-bottom-row">
                          {seletedPlan != null ? (
                            <div className="flex">
                                <div className="steps-bottom-row__month-payment-box">
                                    <span className="p4">Monthly Payment</span>
                                    <h3 className="h3">
                                        {formatMoney(seletedPlan?.billing[0].monthly.price)}
                                    </h3>
                                </div>
                                {/* <div className="steps-bottom-row__month-payment-box">
                                    <span className="p4">Installed in</span>
                                    <h3 className="h3">3-10 days</h3>
                                </div> */}
                            </div>
                          ) : ''
                          }
                          <div className="button-box">
                              <button className="btn btn-primary-outline" onClick={prevStep}>
                                  {" "}
                                  Back{" "}
                              </button>
                              <button
                                className="btn bg-primary-700 text-white" onClick={nextStep}
                              >
                                  {" "}
                                  Next{" "}
                              </button>
                          </div>
                      </div>
                  </div>
              </main>
          </>
        )
    }else{
        return( <LoaderComponent values="Loading....."/>);
    }

}

export default InternetPlansComponent;